// eslint-disable-next-line no-unused-vars

// polyfills
import './polyfills/forEach' // IE fix forEach-function
import './polyfills/scrollingElement' // (?) fix scrolling
import vh_fix from './polyfills/vh_fix' // Smartphone fix 100vh

// gsap
import { gsap } from "gsap"; 
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Draggable } from "gsap/Draggable";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable);

// components
import scrolling from './components-lucasrees_com/scrolling';
import scrolltrigger from './components-lucasrees_com/scrolltrigger';
import interactive_title from './components-lucasrees_com/interactive_title';
import activity_clock from './components-lucasrees_com/activity_clock';
import scroll_links from './components-lucasrees_com/scroll_links';

import word_shuffle from './components-lucasrees_com/word_shuffle';
import experience_icons from './components-lucasrees_com/experience_icons';
import encode_email from './components-lucasrees_com/encode_email';
import accordions from './components-lucasrees_com/accordion';

(() => {
    if ('ontouchstart' in document.documentElement) {
        document.body.classList.add('is-touch')
    }
    const { userAgent } = window.navigator;
    if (userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Trident/') > 0) {
        document.body.classList.add('is-ie')
    }
})

document.addEventListener('DOMContentLoaded',(event) =>{
    new vh_fix()
    new scrolling( gsap )
    new scrolltrigger( ScrollTrigger )
    new interactive_title()
    new scroll_links( gsap )
    new experience_icons()
}, false);

document.fonts.onloadingdone = () => {
    document.querySelector("body").classList.add("fontsready")
};

window.addEventListener('load', (event) => { 
    new encode_email()
    new word_shuffle()
    new accordions( gsap, ScrollTrigger )
    new activity_clock()
});