class encode_email {
    constructor(){

        // encode email

        var encEmail = "aGVsbG9AbHVjYXNkaWR0aGlzLmNvbQ==";
        const form = document.getElementById("contact");

        document.querySelectorAll('.mailto_link').forEach(element => {

            element.setAttribute("title", "send Email");
            element.setAttribute("href", "mailto:".concat(atob(encEmail)));
        
        })

        /*
        let encEmail = "aGVsbG9AbHVjYXNkaWR0aGlzLmNvbQ=="
        const forms = document.querySelectorAll('a[href="#mailme"]')
        forms.forEach(function (form) {
            form.setAttribute("title", "Email senden")
            form.setAttribute("href", "mailto:".concat(atob(encEmail)))
        })
        */

    }
}

export default encode_email