class scrolltrigger {
    constructor( ScrollTrigger ) {

        document.querySelectorAll('main > section[id]').forEach(element => {

            let id = element.getAttribute('id')
            let link = document.querySelector("nav a[href='#"+id+"'")

            let navigationOffset = parseFloat( document.querySelector('body > aside nav').offsetTop )
            let linkOffset = parseFloat( link.parentNode.offsetTop )
            let offsetdifference = linkOffset - navigationOffset

            let top_value = "'top-="+(offsetdifference + 1)+"px'"

            ScrollTrigger.create({
                trigger: element,
                start: top_value,
                end: "bottom",
                once: false,
                onEnter: () => { 
                    link.parentNode.classList.add("current") 
                },
                onEnterBack: () => { 
                    link.parentNode.classList.add("current") 
                },
                onLeave: () => { 
                    link.parentNode.classList.remove("current") 
                },
                onLeaveBack: () => { 
                    link.parentNode.classList.remove("current") 
                }
            })

        })

    }
}

export default scrolltrigger