class word_shuffle{

    constructor( ){

        this.element = document.querySelector('header h2')
        this.word = this.element.querySelector('.word_shuffle')
        this.blink = this.element.querySelector('.cursor_blink')

        this.windowInnerHeight = window.innerHeight;

        this.shuffleParameters = {
            skills: [
              'maker of things',
              'educator',
              'industrial design nerd',
              'father of two',
              'speaker',
              'lp collector',
              'wanderer of virtual realities',
              'creative consultant',
              'husband',
              'tech enthusiast',
              'storyteller',
              'craftsman',
              'cinema buff',
              'prompt poet',
              'maker of things',
            ].map(s => `${s}`),
            tail: 4, // how many chars are shuffling
            skillP: 15, // length 'maker of things'
            text: 'maker of things',
            delay: 16, // delay between shuffles
            step: 1,
            timeout: 96, // speed of shuffle
        }

        this.colors = [
            '#E39EB1',
            '#F5DF73',
            '#F0A480',
            '#B45350',
            '#828E76',
            '#806D59', //additional color
            '#8C5F5F', //additional color
            '#dccb6f', //additional color
            '#cbcb8d', //additional color
            '#9b623d', //additional color
            /* '#b2c1cc', //rustforms.net color
            '#caa2a3', //rustforms.net color
            '#e4d1b9', //rustforms.net color
            '#c0eaed', //rustforms.net color
            '#e4bdf6', //rustforms.net color
            '#f4a1f0', //rustforms.net color
            '#f8cad8', //rustforms.net color
            '#afdee8', //rustforms.net color
            '#fdc0ab', //rustforms.net color
            '#cdb6d9', //rustforms.net color
            '#a5e4b1', //rustforms.net color */
        ]

        this.$ = {
            text: this.shuffleParameters.text,
            skill: 0,
            skillI: 0,
            skillP: this.shuffleParameters.skillP,
            direction: 'backward',
            delay: this.shuffleParameters.delay,
            step: undefined,
        }

        let timer = setTimeout(() => {
          this.shuffleWord()
        }, 3160)

        window.addEventListener('blur',() =>{
          document.querySelector('.word_shuffle').classList.add("inactive")
        });
    
        window.addEventListener('focus',() =>{
            document.querySelector('.word_shuffle').classList.remove("inactive")
        });

    }
    
    onResize() {
      this.windowInnerHeight = window.innerHeight;
    }

    getRandomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    }
  
    getRandomChar() {
      return String.fromCharCode(Math.random() * (127 - 33) + 33);
    }

    getRandomColoredString(n) {
      const fragment = document.createDocumentFragment();
      for (let i = 0; i < n; i++) {
        const char = document.createElement('span');
        char.textContent = this.getRandomChar();
        char.style.color = this.getRandomColor();
        fragment.appendChild(char);
      }
      return fragment;
    }

    shuffleWord() {

      window.requestAnimationFrame(() => {

        const rect = this.element.getBoundingClientRect()
        let isInView = rect.top < this.windowInnerHeight && rect.bottom >= 0

        if ( !this.word.classList.contains("inactive") && isInView ) {
          const skill = this.shuffleParameters.skills[this.$.skillI];

          if (this.$.step) {
            this.$.step--;
          } else if (this.$.direction === 'forward') {
            // forward (show word)
            if (this.$.skillP < skill.length) {
              this.$.text += skill[this.$.skillP];
              this.$.skillP++;
            } else if (this.$.delay) {
              this.$.delay--;
              document.querySelector('.cursor_blink').classList.remove("paused");
            } else {
              if (this.$.skillI == (this.shuffleParameters.skills.length - 1)) {
                // reset
    
                this.$.text = 'maker of things';
                this.$.skill = 0;
                this.$.skillI = 0;
                this.$.skillP = this.shuffleParameters.skillP;
                this.$.direction = 'backward';
                this.$.delay = this.shuffleParameters.delay;
                this.$.step = undefined;

                timer = setTimeout(() => {
                  this.shuffleWord();
                }, 3160);
    
                return false;
              }
              this.$.direction = 'backward';
              this.$.delay = this.shuffleParameters.delay;
            }
          } else {
            // backward (remove word)
            if (this.$.skillP > 0) {
              document.querySelector('.cursor_blink').classList.add("paused");
              this.$.text = this.$.text.slice(0, -1);
              this.$.skillP--;
            } else {
              this.$.skillI = (this.$.skillI + 1) % this.shuffleParameters.skills.length;
              this.$.direction = 'forward';
            }
          }
    
          this.word.textContent = this.$.text;
          this.word.appendChild(this.getRandomColoredString(Math.min(this.shuffleParameters.tail, skill.length - this.$.skillP)));
        }
    
        timer = setTimeout(() => {
          this.shuffleWord();
        }, this.shuffleParameters.timeout);

      })

    }

}

export default word_shuffle